import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    backgroundImage: {
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        [theme.breakpoints.up('md')]: {
            backgroundSize: [['contain'], '!important'],
        },
    },
    image: {
        marginTop: '10vh',
        position: 'relative',
        overflow: 'hidden',
        display: 'inline-block',
        width: [[360], '!important'],
        height: [[241], '!important'],
        [theme.breakpoints.down('md')]: {
            width: [[280], '!important'],
            height: [[187], '!important'],
        },
        [theme.breakpoints.down('xs')]: {
            width: [[200], '!important'],
            height: [[134], '!important'],
        },
    },
    title: {
        color: theme.palette.logo.blue.main,
        marginTop: theme.spacing(2),
    },
    description: {
        fontWeight: '400',
        marginTop: theme.spacing(3),
        padding: theme.spacing(0, 2),
    },
    button: {
        marginTop: theme.spacing(7),
    },
    contactUs: {
        display: 'inline-block',
        marginTop: theme.spacing(1.5),
    },
}));
